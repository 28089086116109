<template>
  <div class="header">
    <div class="title">Factory Audit Report</div>
    <div class="header-container m-t-36">
      <div class="factory f-white">
        <div class="flex m-l-16">
          <img src="" alt="" />
          <div class="f-22 f-bold">FACTORY</div>
        </div>
        <div class="f-18 m-t-14">Virtuos</div>
        <div class="f-16 m-t-10">
          No.99 East Street, Chengdu, Sichuan Province
        </div>
      </div>
      <div class="header-border-left"></div>
      <div class="factory f-white">
        <div class="flex m-l-16">
          <img src="" alt="" />
          <div class="f-22 f-bold">SUPPLIER</div>
        </div>
        <div class="f-18 m-t-14">Foxconn</div>
        <div class="f-16 m-t-10">
          No.99 East Street, Chengdu, Sichuan Province
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pdf",
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

.header {
  width: 100vw;
  height: 285px;
  background: #2ec9c3;
}

.title {
  font-size: 36px;
  color: #ffffff;
  font-weight: bold;
  padding-top: 28px;
  width: 356px;
  margin: auto;
  white-space: nowrap;
}

.header-container {
  display: flex;
  justify-content: center;
  width: 1069px;
  margin: auto;
}

.factory {
  width: 346px;
  height: 140px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.header-border-left {
  border-left: 1px dashed #ffffff;
  margin: 0 190px;
}

.f-36 {
  font-size: 36px;
}

.f-22 {
  font-size: 22px;
}

.f-18 {
  font-size: 18px;
}

.f-16 {
  font-size: 16px;
}

.f-bold {
  font-weight: bold;
}

.flex {
  display: flex;
}

.m-l-16 {
  margin-left: 16px;
}

.m-l-190 {
  margin-left: 190px;
}

.m-t-36 {
  margin-top: 36px;
}

.m-t-14 {
  margin-top: 14px;
}

.m-t-10 {
  margin-top: 10px;
}

.f-white {
  color: #ffffff;
}
</style>
